<template>
	
	
	<div v-if="navigation" :style="{'backgroundImage':'url('+navigation.bgImg+')'}" style="background-size: cover;">
		<el-row :gutter="24" class="bg" :style="[{'background':navigation.bgColour}]">
		  <el-col :span="6" style="padding-top: 10px;">
			
			<dv-border-box-4 :reverse="true" style="width:100%;height:80px;" :color="[navigation.borderColour, navigation.borderSecondaryColour]">
				<div class="title" :style="[{'color':navigation.titleColour},{'margin':navigation.titleMargin+'px'},{'lineHeight':navigation.titleHeight+'px'},{'fontSize':navigation.titleSize+'px'}]">
					{{navigation.title}}
				</div>
			</dv-border-box-4>
			
		 </el-col>
		  <el-col :span="18" >
			<dv-decoration-3 style="width:180px;height:50px;float: left; margin-top: 10px;" :color="[navigation.borderColour, navigation.borderSecondaryColour]"/>
			<ul class="menu_panel">
				<router-link v-for="(item,index) in navigationChildren" :key="index" 
				:to="'directoryView?id='+item.id+'&navigationId='+navigationId">
				<li :style="[{'color':navigation.textColour},{'margin':navigation.textMargin+'px'},{'lineHeight':navigation.textHeight+'px'},{'fontSize':navigation.textSize+'px'}]">
					{{item.name}} 
				</li>
				</router-link>
			</ul>

			<dv-decoration-4 :reverse="true"    style="top: 70px;width:100%;height:5px;" :color="['#07d5c0']" />
			<div style="width:100%;height: 2px;background-color:#0c564b;top: 70px;position: relative;"></div>



		  </el-col>
		</el-row>

		
		

		<router-view></router-view>
	
	</div>
	  

	
	
</template>

<script>
import { getNavigation }
	from "@/api/navigation";

export default {
  name: "preview_index",
  data(){
    return{
		navigationId:null,
		navigation:null,
		navigationChildren:[],
		
	}
  },
  watch: {
	  
  },
  mounted() {
	    this.navigationId = this.$route.query.navigationId;
		
		getNavigation(this.navigationId).then(res=>{
			this.navigation=res.data;
			this.navigationChildren=this.navigation.children;
			console.log(this.navigationChildren);
			
			
		})
  },
  methods:{
	  
  },
  
  
}
	
	
	
	
</script>




<style >

.title{
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding-top: 10px;
  width: 85%;
  text-align: right;
  text-shadow: -1px 1px 2px #fff;
  padding-bottom: 0px;
  padding-right: 0px;

}
.menu_panel{list-style: none;font-size: 18px;color: #fff;float: left;height: 30px;width: 82%;float: right;}
.menu_panel li{float: left;line-height: 40px;font-weight: bold;}
</style>