<template>
	<div>
		
		
		
		<el-dialog title="API列表选择" :visible.sync="openWindows" 
		width="850px" append-to-body>
		
			<el-form :model="queryParams" ref="queryForm" size="small" :inline="true"
			  label-width="68px">
			  <el-form-item label="名称" prop="title">
			    <el-input
			      v-model="queryParams.title"
			      placeholder="请输入名称"
			      clearable
			      @keyup.enter.native="handleQuery"
			    />
			  </el-form-item>
			
			  <el-form-item label="类型" prop="type">
			    <el-select v-model="queryParams.type" placeholder="请选择类型" clearable>
			      <el-option
			        v-for="dict in designApiTypeList"
			        :key="dict.dictValue"
			        :label="dict.dictLabel"
			        :value="dict.dictValue"
			      />
			    </el-select>
			  </el-form-item>
			  <el-form-item>
			    <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
			    <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
			  </el-form-item>
			</el-form>
		
		
			 <div class="app-container">
				 <el-table v-loading="loading" 
				 :data="listData">
				   <el-table-column label="ID" align="center" prop="id" />
				   <el-table-column label="接口" align="center" prop="title" />
				   <el-table-column label="类型" align="center" prop="type" >
						<template slot-scope="scope">
							
							<teleport  v-for="(dict,index) in designApiTypeList" :key="index">
								
								<b v-if="dict.dictValue==scope.row.type">
									{{dict.dictLabel}}
								</b>
								
							</teleport>
							
						</template>
				   </el-table-column>
				   <el-table-column label="url" align="center" prop="url" />
				   <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
				     <template slot-scope="scope">
				     	<el-button	size="mini" type="text" icon="el-icon-s-promotion"   @click="handleSet(scope.row,scope.$index)">使用</el-button>
				     </template>
				   
				   </el-table-column>
				 </el-table>
				 
				 <el-pagination background style="margin-top: 20px"
				                @size-change="handleSizeChange" @current-change="handleCurrentChange"
				                :current-page="queryParams.pageNo" :page-size="queryParams.pageSize"
				                :page-sizes="[10, 30, 50, 100]" :total="total"
				                layout="total, sizes, prev, pager, next, jumper">
				 </el-pagination>
				 
			 </div>
		
		
		</el-dialog>
		
		
		
		
		
		
	</div>
	
</template>

<script>

import { listDesignApi } from "@/api/DesignerApi";

import { getDicts } from "@/api/dict/data";

	
export default {
  name: "apiWindows",
  dicts:["designApiType"],
  props: {
    /* 窗口 */
    isWindows: {
        type: Boolean,
        default: false,
    },
 },
 watch: {
	isWindows: {
	  handler(val) {
	   this.openWindows=val;
	  },
	  immediate: true,
	},
	
	openWindows: {
	  handler(val) {
	   this.openWindows=val;
	   this.$emit("openWindows",val);
	  },
	  immediate: true,
	},
  
 },
 data() {
   return {
	   designApiTypeList:[],
	total:0,
	openWindows:true,
	loading:false,
	listData:[],
	queryParams: {
	  pageNum: 1,
	  pageSize: 10,
	  url: null,
	  title: null,
	  type:null,
	},
   }
 },
 created() {
  
  //  getDicts("designApiType").then(res=>{
	 // this.designApiTypeList=  res.data;
	  
  //  })
   
  // this.loadData();
   
 },
 methods: {
	 
	 
	 
	 handleSizeChange(val) {
	   this.queryParams.pageSize = val;
	   this.loadData();
	 },
	 handleCurrentChange(val) {
	   this.queryParams.pageNo = val;
	   this.loadData();
	 },
	  //获取数据
	   loadData(){
		   this.loading=true;
		   listDesignApi( this.queryParams).then(res=>{
			    this.loading=false;
			   this.listData=res.rows
			     this.total = res.total;
		   });
	   },
	   
	   //发送并且关闭
	   handleSet(row,index){
		    this.$emit("selApi",row);
			this.openWindows=false;
	   },
	   
	   // 表单重置
	   reset() {
	     this.queryParams = {
			 pageNum: 1,
			 pageSize: 10,
	       id: null,
	       url: null,
	       title: null,
	       remark: null
	     };
	   
	   },
	   /** 搜索按钮操作 */
	   handleQuery() {
	     this.queryParams.pageNum = 1;
	     this.loadData();
	   },
	   /** 重置按钮操作 */
	   resetQuery() {
	     this.reset();
	     this.handleQuery();
	   },
 }



}
	
	
	
</script>

<style>
</style>