import { render, staticRenderFns } from "./cpt-rect-num-option.vue?vue&type=template&id=6a60dd34&scoped=true&"
import script from "./cpt-rect-num-option.vue?vue&type=script&lang=js&"
export * from "./cpt-rect-num-option.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a60dd34",
  null
  
)

export default component.exports