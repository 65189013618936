<template>
  <el-container style="height: 100%;">
    <el-aside width="200px" style="background-color: #49586e;min-height: 300px">
      <div style="padding-top: 20px;height: 200px;text-align: center;color: #eee">
        <div v-if="currentUser.user">
          <el-avatar :size="80" :src="'https://wei.kongtangweishi.com/'+currentUser.user.headPortrait"></el-avatar>
        </div>
        <el-dropdown v-if="currentUser.user" style="margin-top: 20px;" trigger="click" @command="handleUserCommand">
          <span class="el-dropdown-link">
            {{currentUser.user.name}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div style="margin-top: 20px;">
		  <div v-for="item in manageMenus" :key="item.name">
			  
        <div class="menuItem"  @click="redirectMenu(item)"  v-show="item.meta&&!item.meta.hidden"
             :class=" item.path=== key ? 'menuActive':''">{{item.meta.title}}</div>
			 
			 </div>
      </div>
    </el-aside>
    <el-main style="background-color: #fafafa;padding: 0px;">
		<div style="background-color: #fff;height: 50px;padding: 20px;width: 100%;">
			<el-breadcrumb separator="/">
			  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			  <el-breadcrumb-item>{{thisMenuItem.meta.title}}</el-breadcrumb-item>
			</el-breadcrumb>
			
		</div>
		
		
      <div style="padding: 20px;">
		  
		  <router-view :key="key" />
		  
	  </div>
    </el-main>
  </el-container>
</template>

<script>
import { manageMenus } from '@/router'
import {removeToken} from "@/utils/auth";
export default {
  name: "Layout",
  computed: {
    key() {
      return this.$route.path
    },
    manageMenus() {
      return manageMenus
    },
    currentUser(){
      return JSON.parse(localStorage.getItem("userinfo"))
    }
  },
  data(){
  	return{
		thisMenuItem:manageMenus[0],
	}
  },
  created() {
  var userinfo=	localStorage.getItem("userinfo");
  console.log(userinfo)
  if(!userinfo){
	  removeToken();
	  this.$router.push('/login');
  }
  },
  methods:{
    redirectMenu(item){
		this.thisMenuItem=item;
      this.$router.push(item.path)
    },
    handleUserCommand(command){
      if (command === 'logout'){
        removeToken();
        this.$router.push('/login');
      }
    }
  }
}
</script>

<style scoped>
.menuItem{line-height: 45px;padding-left: 40px;cursor: pointer;color: #eee;font-size: 14px;}
.menuItem:hover{background-color: #3F4B5F}
.menuActive{color: #409eff;background-color: #3F4B5F}
.el-dropdown-link {cursor: pointer; color: #eee;}
</style>
