<template>
  <div>
    <el-form label-width="100px" size="mini">
      <el-form-item label="视频选择">
<!--        <div class="formItemDiv" @click="showGallery">图库选择</div>-->
        <video @click="showGallery" style="width: 168px; height: 160px"
                  :src="attribute.url ? attribute.url : 'https://qianniaoyun.oss-cn-beijing.aliyuncs.com/public/mp4/xuanchuanyanshi.mp4'" fit="fill"/>
      </el-form-item>
	  <el-form-item label="视频地址">
	   <el-input  v-model="attribute.url" style="width: 100%"/>
	   
	  </el-form-item>
	  
	  
	  
 <!--     <el-form-item label="填充方式">
        <el-select v-model="attribute.fit" placeholder="请选择填充方式">
          <el-option label="fill" value="fill"/>
          <el-option label="contain" value="contain"/>
          <el-option label="cover" value="cover"/>
          <el-option label="none" value="none"/>
          <el-option label="scale-down" value="scale-down"/>
        </el-select> 
      </el-form-item>-->
	  
	  <el-form-item label="标题">
	   <el-input  v-model="attribute.title" style="width: 100%"/>
	   
	  </el-form-item>
	  
      <el-form-item label="标题颜色">
        <el-color-picker v-model="attribute.titleColor" show-alpha/>
      </el-form-item>
	  
	  <el-form-item label="背景颜色">
	    <el-color-picker v-model="attribute.titleBgColor" show-alpha/>
	  </el-form-item>
	  
	  
	  <el-form-item label="边距">
	    <el-input-number :min="1" :max="200" v-model="attribute.padding" style="width: 100%"/>
	  </el-form-item>
	  
	  <el-form-item label="高度">
	    <el-input-number :min="13" :max="200" v-model="attribute.titleHeight" style="width: 100%"/>
	  </el-form-item>
	  
	  <el-form-item label="文字大小">
	    <el-input-number :min="13" :max="200" v-model="attribute.textSize" style="width: 100%"/>
	  </el-form-item>
	  <el-form-item label="行高">
	    <el-input-number :min="13" :max="250" v-model="attribute.textLineHeight" style="width: 100%"/>
	  </el-form-item>
	  
	  <el-form-item label="自动播放">
	    <el-radio-group v-model="attribute.play">
	      <el-radio :label="true">是</el-radio>
	      <el-radio :label="false">否</el-radio>
	    </el-radio-group>
	  </el-form-item>
	  
	  <el-form-item label="静音播放">
	    <el-radio-group v-model="attribute.muted">
	      <el-radio :label="true">是</el-radio>
	      <el-radio :label="false">否</el-radio>
	    </el-radio-group>
	  </el-form-item>
	  
	  <el-form-item label="显示控制栏">
	    <el-radio-group v-model="attribute.bar">
	      <el-radio :label="true">是</el-radio>
	      <el-radio :label="false">否</el-radio>
	    </el-radio-group>
	  </el-form-item>
	  
	  
	  
    </el-form>
    <gallery ref="gallery" @confirmCheck="confirmCheck"/>
  </div>
</template>

<script>
import Gallery from "@/components/gallery";
import {fileUrl} from "/env";
export default {
  name: "cpt-video-option",
  components: {Gallery},
  props: {attribute: Object},
  data(){
    return {
      fileUrl:fileUrl,
    }
  },
  methods:{
    showGallery(){
      this.$refs.gallery.opened();
    },
    confirmCheck(fileUrl){
      this.attribute.url = fileUrl
    }
  }
}
</script>

<style scoped>
.formItemUpload{width: 120px; height: 120px;background-color: #fff;border-radius: 6px;text-align: center;line-height: 120px;font-size: 40px;color: #aaa}
</style>
