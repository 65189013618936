import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts';
import router from './router';
import dataV from '@jiaminghi/data-view';
import '@/permission'; // permission control
import '@/components/register-option'
import '@/components/register-cpt'
import '@/assets/font/custom-font.css';
import '@/utils/RegisterMap';

Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(dataV);

Vue.prototype.DoctorTitle=[{text:'住院医师',value:'physician'},{text:'主治医师',value:'ap'},
			{text:'副主任医师',value:'adp'},
			{text:'主任医师',value:'dp'},{text:'营养师',value:'dietitian'},{text:'未知',value:'unknown'},
			{text:'护师',value:'nurse'},{text:'主管护师',value:'nurseInCharge'},{text:'副主任护师',value:'adNurse'},
			{text:'主任护师',value:'apNurse'},{text:'助理医师',value:'assistant'}];
			
			Vue.prototype.consultationAudit=[
				 {text:'等待专家确认',value:'toBeConfirmed'},{text:'等待开始',value:'notStarted'}
				,{text:'专家拒绝',value:'refused'},{text:'结束',value:'finished'}
				,{text:'投诉',value:'complained'},{text:'结算',value:'settlement'}
				,{text:'取消',value:'cancel'},
				{text:'未支付',value:'noPay'}];


new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app');
