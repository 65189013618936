import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import {getToken, removeToken} from "@/utils/auth";
import errorCode from '@/utils/errorCode'

export let isRelogin = { show: false };
const formData = new FormData();

const httpUtil = axios.create({
	baseURL: "https://wei.kongtangweishi.com",

	//baseURL: "http://localhost:8080/qixuanzhilian_web_war",
	headers: {
		
						
						'Accept': '*/*',
						"content-qixuan-type":"qixuan",
						'Accept-Language': 'zh-CN,zh;q=0.9,en;q=0.8',
						
						
						
					},
    timeout: 12000 // request timeout
})
httpUtil.interceptors.request.use(
    config => {
        const token = getToken();
        if (token) {
            config.headers['Authorization'] = token
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

httpUtil.interceptors.response.use(
    res => {
       
	   
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    // 二进制数据则直接返回
    if (res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer') {
      return res.data
    }
    if (code === 401) {
      if (!isRelogin.show) {
        isRelogin.show = true;
        MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
          isRelogin.show = false;
		  
          // store.dispatch('LogOut').then(() => {
          //   location.href = '/index';
          // })
		  removeToken();
		  //this.$router.push('/login');
		  
			location.href = '/#/login';
		  
      }).catch(() => {
        isRelogin.show = false;
      });
    }
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code === 500) {
      Message({ message: msg, type: 'error' })
      return Promise.reject(new Error(msg))
    } else if (code === 601) {
      Message({ message: msg, type: 'warning' })
      return Promise.reject('error')
    } else if (code !== 200) {
      Notification.error({ title: msg })
      return Promise.reject('error')
    } else {
      return res.data
    }
	   
	   
    }, error => {
        console.log('err', error)
        Message.error(error.message)
        return Promise.reject(error)
    }
)
httpUtil.doRequest = function (url, method, params) {
    return httpUtil({
        url: url,
        method: method,
        params
    })
}
httpUtil.doGet = function (url, params) {
    return httpUtil({
        url: url,
        method: 'get',
        params
    })
}
httpUtil.doPost = function (headers, url, data) {
    return httpUtil({
        headers: headers,
        url: url,
        method: 'post',
        data: data
    })
}



export default httpUtil
