function dynamicLoadScript(src) {
    return new Promise((resolve, reject) => {


        if(src.type === 'js'){
            const script = document.createElement('script');
            script.src = src.url;

            script.onload = function () {
                resolve(src.url);
            };
            script.onerror = function () {
                reject(new Error('Failed to load ' + src.url), script);
            };
            document.body.appendChild(script);
        }else if(src.type === 'css'){
            const link = document.createElement('link')
            link.rel = 'stylesheet';
            link.href = src.url;
            link.onload = function () {
                resolve(src.url);
            };
            link.onerror = function () {
                reject(new Error('Failed to load ' + src.url), link);
            };
            document.head.appendChild(link);
        }

    });
}

// 动态加载多条js
function loadAllResource(urls) {

    return Promise.all(urls.map((url) => dynamicLoadScript(url)));
}

export const ckplayerObj = new Promise((resolve) => {
    if(window.WynBi){
        resolve();
    }else{

        const urls = [

				{url:'https://qianniaoyun.oss-cn-beijing.aliyuncs.com/public/ckplayer/css/ckplayer.css',type:'css'},
			  {url:'https://qianniaoyun.oss-cn-beijing.aliyuncs.com/public/ckplayer/flv.js/flv.min.js',type:'js'},
				{url:'https://qianniaoyun.oss-cn-beijing.aliyuncs.com/public/ckplayer/js/ckplayer.js',type:'js'},


		]
        loadAllResource(urls).then(() => {

            resolve();
			
        });
    }
});
