import request from '@/utils/HttpUtil'
import { baseUrl } from '/env'

export function getByIdApi(id,mode,viewCode) {
    return request({
        url: baseUrl+'/api/auxiliary/auxiliaryDesignData/getById/'+id+'/'+mode+'/'+viewCode,
        method: 'get'
    })
}

export function authViewCodeApi(params) {
    return request({
        url: baseUrl+'/api/auxiliary/auxiliaryDesignData/authViewCode',
        method: 'get',
        params
    })
}

export function uploadFileApi(fileName, data) {
    return request({
        headers: {'Content-Type':'multipart/form-data'},
        url: baseUrl+'/file/upload?dir=pre&fileName='+fileName,
        method: 'post',
        data: data
    })
}

export function saveOrUpdateApi(data) {
    return request({
        url: baseUrl+'/api/auxiliary/auxiliaryDesignData/saveOrUpdate',
        method: 'post',
        data
    })
}


export function pageListApi(params) {
    return request({
        url: baseUrl+'/api/auxiliary/auxiliaryDesignData/pageList',
        method: 'get',
        params
    })
}
export function deleteApi(id) {
    return request({
        url: baseUrl+'/api/auxiliary/auxiliaryDesignData/delete?id='+id,
        method: 'delete'
    })
}

// 查询大屏API列表
export function listDesignApi(query) {
  return request({
    url: '/api/auxiliary/auxiliaryDesignData/designApi/list',
    method: 'get',
    params: query
  })
}