<template>
  <div style="width: 100%; height: 100%;overflow: hidden;" >
	 
	  
	  <div style="width: 100%; height: 100%"  :id="videoId">
	  	<!-- <video style="width: 100%; height: 100%"
	  	      :preview-src-list="option.attribute.preview ? [option.attribute.url]:[]"
	  	      :src="option.attribute.url?fileUrl+option.attribute.url:'https://qianniaoyun.oss-cn-beijing.aliyuncs.com/public/mp4/xuanchuanyanshi.mp4'" :fit="option.attribute.fit"/> -->
	  	
	  </div>
	  
	  <div v-if="option.attribute.title.length>0"
	   style="text-align: center;z-index: 99999999999;
	   width: 100%;position: absolute;top:1px" 
	  :style="[{'background-color':option.attribute.titleBgColor}, {'height':option.attribute.titleHeight+'px'},{'font-size':option.attribute.textSize+'px'},{'color':option.attribute.titleColor},
	  {'padding':option.attribute.padding+'px 0px'},{'line-height':option.attribute.textLineHeight+'px'}]"
	  >
	  
	  {{option.attribute.title}}
	  </div>
	  
  </div>
</template>

<script>

import {ckplayerObj} from "@/components/publicJS/ckplayer";

export default {
  name: "cpt-video",
  props: {option: Object},
  data() {
    return {
	 videoObj:null,
      fileUrl:'',
	  videoId:'1212',
	  videUrl:'https://qianniaoyun.oss-cn-beijing.aliyuncs.com/public/mp4/xuanchuanyanshi.mp4',
	  
    }
  },
  watch: {
    "option.attribute.url": {
      handler(val) {
		  if(val!=null&&val.length>10){
			  this.videUrl=val;
			   this.init();
		  }
		  
      },
      immediate: true,
    },
	
	"option.attribute.bar": {
	  handler(val) {
		 if( this.videoObj){
			 this.videoObj.bar(val);
		 }
		 
		  
	  },
	  immediate: true,
	},
	
	
	
  },
  created() {
	  this.videoId='video'+require('uuid').v1(),
	  this.init()
	
  },
   methods: {
	   init(){
		   ckplayerObj.then(()=>{
			var fileType="mp4";
			var fileExtension = this.videUrl.substring(this.videUrl.lastIndexOf(".") + 1);
			console.log(fileExtension);
			   
			var videoObject = {
					container: '#'+this.videoId, //容器的ID或className
				  //plug:'flv.js',//设置使用flv插件
					video:this.videUrl,
					rightBar:false,
			 };
			 if(fileExtension=="flv"){
				 videoObject.plug='flv.js'
			 }
			 if(fileExtension=="m3u8"){
			 	videoObject.plug='hls.js'
			 }
			 if(fileExtension=="ts"){
			 	videoObject.plug='mpegts.js'
			 }
			 if(fileExtension=="mpd"){
			 	videoObject.plug=function(video,fileUrl){
					video.attr('data-dashjs-player',' ');
					video.attr('src',fileUrl);
				}
			 }
			 
			 //rightBar 显示控件
			 //muted 静音
			 //play  播放
			 
			 this.videoObj=new ckplayer(videoObject);
			
			
			if(this.option.attribute.play){
				 this.videoObj.play();
			}
			if(this.option.attribute.muted){
				 this.videoObj.muted();
			}else{
				 this.videoObj.exitMuted();
			}
			
			 this.videoObj.bar(this.option.attribute.bar);
			
			
			
		   })
		   
	   }
	   
   }
}
</script>

<style scoped>
</style>
