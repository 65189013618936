import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Designer from '@/views/designer/index'
import Preview from '@/views/preview/index'
import Layout from "@/views/manage/Layout";
import DesignList from "@/views/manage/DesignList";
import ImgPool from "@/views/manage/ImgPool";
import Login from "@/views/manage/Login";
import directoryPreview from '@/views/preview/directoryPreview';


export const manageMenus = [
	
	{
		path: '/index',
		name: 'index',
		component: () => import('@/views/manage/index'),
		meta: { title: '首页', icon: 'el-icon-data-line' },
	
		//meta: { hidden: false } // 默认显示 
	},
	
    {
        path: '/designList',
        name: 'DesignList',
        component: DesignList,
        meta: { title: '数据驾驶舱', icon: 'el-icon-data-line' }
    }, 
	
	{
        path: '/imgPool',
        name: 'imgPool',
        component: ImgPool,
		hidden: true,
        meta: { title: '资源库管理', icon: 'el-icon-data-line',hidden: true }
    },{
		path: '/myconsultment',
		name: 'myconsultment',
		component: () => import('@/views/myreservation/consultment'),
		meta: { title: '我的会诊', icon: 'el-icon-data-line' }
	},{
		path: '/mytransferHospital',
		name: 'mytransferHospital',
		component: () => import('@/views/myreservation/transferHospital'),
		meta: { title: '我的转院', icon: 'el-icon-data-line' },
	
		//meta: { hidden: false } // 默认显示 
	},{
		path: '/queTransferHospital',
		name: 'queTransferHospital',
		component: () => import('@/views/appointment/addTransferHospital/queTransferHospital'),
		meta: { title: '预约转院', icon: 'el-icon-data-line' }
	},{
		path: '/addTransferHospital',
		name: 'addTransferHospital',
		component: () => import('@/views/appointment/addTransferHospital/addTransferHospital'),
		meta: { title: '申请转院', icon: 'el-icon-data-line',hidden: true  }
	},{
		path: '/queConsultment',
		name: 'queConsultment',
		component: () => import('@/views/appointment/addConsultment/queConsultment'),
		meta: { title: '预约会诊', icon: 'el-icon-data-line' }
	},{
		path: '/addConsultment',
		name: 'addConsultment',
		component: () => import('@/views/appointment/addConsultment/addConsultment'),
		meta: { title: '申请会诊', icon: 'el-icon-data-line',hidden: true  }
	}
	,{
		path: '/transferHospital',
		name: 'transferHospital',
		component: () => import('@/views/appointment/transferHospital'),
		meta: { title: '转院数据', icon: 'el-icon-data-line' }
	},{
		path: '/consultation',
		name: 'consultation',
		component: () => import('@/views/appointment/consultation'),
		meta: { title: '会诊数据', icon: 'el-icon-data-line' }
	}
	
	/* ,{
        path: '/imgGroup',
        name: 'imgGroup',
        component: () => import('@/views/manage/ImgGroup'),
        meta: { title: '资源分组管理', icon: 'el-icon-data-line' }
    },*/
]

// 创建 router 实例，然后传 `routes` 配置
export default new VueRouter({
    // mode: 'history',
    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login,
        }, {
            path: '/',
            component: Layout,
            redirect: '/index',
            children: manageMenus
        }, {
            path: '/design',
            name: 'Designer',
            component: Designer
        }, {
            path: '/preview',
            name: 'preview',
            component: Preview
        }, {
            path: '/view',
            name: 'view',
            component: Preview
        },{
			
			path: '/directoryPreview',
			name: 'directoryPreview',
			component:directoryPreview,
			children:[{
				path: '/directoryView',
				name: 'directoryView',
				component: Preview
			}]
		}
    ]
})
