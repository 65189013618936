import request from '@/utils/HttpUtil'
import { baseUrl } from '/env'
import { getToken, setToken, removeToken } from '@/utils/auth'

export function login(data) {
  return request({
    url: '/kongtangweishi/api/login/login',
    method: 'post',
    params: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}


export function logout() {
  return request({
    url: '/kongtangweishi/api/userInfo/getUserInfo',
      method: 'get',
      params: {
        type:'doctor',
        id:getToken()
      }

  })
}

export function pageListApi(params) {
  return request({
    url: baseUrl+'/sysUser/pageList',
    method: 'get',
    params
  })
}
export function saveOrUpdateApi(data) {
  return request({
    url: baseUrl+'/sysUser/saveOrUpdate',
    method: 'post',
    data
  })
}
