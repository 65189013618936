import request from '@/utils/HttpUtil'

// 查询导航栏设计列表
export function listNavigation(query) {
  return request({
    url: '/design/navigation/list',
    method: 'get',
    params: query
  })
}

// 查询导航栏设计详细
export function getNavigation(id) {
	
  return request({
    url: '/design/navigation/' + id,
    method: 'get'
  })
}

// 新增导航栏设计
export function addNavigation(data) {
  return request({
    url: '/design/navigation/saveOrUpdate',
    method: 'post',
    data: data
  })
}

// 修改导航栏设计
export function updateNavigation(data) {
  return request({
    url: '/design/navigation/saveOrUpdate',
    method: 'post',
    data: data
  })
}

// 删除导航栏设计
export function delNavigation(id) {
  return request({
    url: '/design/navigation/' + id,
    method: 'delete'
  })
}
